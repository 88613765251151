.inputField {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  margin-right: 20px;
}

.label {
  font-size: 16px;
  color: white;
  margin: 10px;
  align-self: center;
}

.input {
  height: 20px;
  width: 200px;
  padding: 10px;
}
