.Home {
  text-align: center;
}

.Home-header {
  background-color: #282c34;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.input-row {
  display: flex;
  align-items: center;
  margin: 16px;
}

.input-label {
  display: flex;
  font-size: 24px;
  width: 400px;
  justify-content: flex-end;
  margin-right: 8px;
}

.number-input {
  height: 30px;
  font-size: 18px;
  border: black;
  border-radius: 8px;
  padding: 8px;
  margin-left: 8px;
}