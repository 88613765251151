.Home {
  text-align: center;
}

.Home-header {
  background-color: #282c34;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.inputRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.submitButton {
  background: rgb(73, 146, 255);
  color: white;
  padding: 10px;
  width: 100px;
  border: none;
  cursor: pointer;

  &:hover {
    background: rgb(22, 99, 214);
  }
}

.gameRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
}

.game {
  background-color: #1f202a;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  margin: 5px 5px;
}
