.App {
  background-color: #282c34;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-width: 100wh;
}

.App-header {
  background-color: #212329;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  color: white;
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo {
  color: white;
  font-size: 24px;
  background: none;
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: rgb(179, 193, 212);
  }
}

.userButtonContainer {
  display: flex;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
}

.userButton {
  background: rgb(73, 146, 255);
  color: white;
  padding: 10px;
  width: 100px;
  border: none;
  cursor: pointer;

  &:hover {
    background: rgb(22, 99, 214);
  }
}
